<template>
  <div id="App">
    <index-header />
    <div class="mains">
      <router-view />
    </div>
    <index-footer />
  </div>
</template>

<script>
import IndexFooter from "./components/IndexFooter.vue";
import IndexHeader from "./components/IndexHeader.vue";
export default {
  components: { IndexHeader, IndexFooter },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
a,
input {
  display: block;
}
img {
  display: inline-block;
}
#App {
  // padding: 0 77px;
  background: #eaeae3;
  .mains {
    // padding: 0 77px;
    width: 1200px;
    margin: 0 auto;
    background: #eaeae3;
  }
}
.lin_1 {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all; /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis; /* 超出部分省略号 */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /** 显示的行数 **/
}
.lin_1_1{
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all; /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis; /* 超出部分省略号 */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /** 显示的行数 **/
  padding-right: 20px;
  flex: 1;
}
.i_f {
  width: 100%;
  // display: inline-flex;
}
</style>
