<template>
  <div id="footer">
    <div class="footer">
      <div class="footer_left">
        <div class="text">
          <div class="round"></div>
          <div style="font-size: 30px">高陵区公益性公墓</div>
        </div>
        <div class="iphone">
          <span v-if="info.phone">联系电话</span>:{{ info.phone }}
          <div v-if="info.addr">
            <span>地址：{{ info.addr }}</span>
          </div>
        </div>
      </div>
      <div class="code_img">
        <!-- 图片 -->
        <img v-if="info.img1" :src="info.img1" style="margin-left: 0" />

      </div>
      <div class="footer_sright">
        <div class="right_main">
          <img src="https://jisi.guangruankeji.com/public/D:/ruoyi/uploadPath/upload/2023/08/16/qrcode_for_gh_04940c3380a5_1280_20230816153026A065.jpg" />
          <p>手机端</p>
        </div>
        <div class="right_main">
          <img src="https://jisi.guangruankeji.com/public/D:/ruoyi/uploadPath/upload/2023/08/16/qrcode_for_gh_04940c3380a5_1280_20230816153026A065.jpg" />
          <p>预约祭扫</p>
        </div>
        <div class="right_main">
          <img src="https://jisi.guangruankeji.com/public/D:/ruoyi/uploadPath/upload/2023/08/16/qrcode_for_gh_04940c3380a5_1280_20230816153026A065.jpg" />
          <p>代客祭扫</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  companyinfoSSelectOne,
  cemeteriesSelectpage,
  cemeteriesSelectbyid,
} from "@/api/selectpage";
export default {
  data() {
    return {
      info: "",
      introduction:''
    };
  },
  created() {
    companyinfoSSelectOne().then((res) => {
      this.info = res.data;
    });
  },
  methods: {
  },
};
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}
#footer {
  width: 100%;
  margin: 25px 0 0 0;
  .footer_sright{
    position: fixed;
    right: 0px;
    bottom: 200px;
    background: #0ba09d;
    padding: 20px 20px 0px 20px;
    z-index: 10;
    .right_main{
      margin-bottom: 20px;
      img{
        display: block;
        width: 120px;
        height: 120px;
      }
      p{
        margin: 10px 0 0 0 ;
        text-align: center;
        color: #ffffff;
      }
    }

  }
  .footer {
    background-color: #0ba09d;
    color: #fff;
    height: 197px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 222px;
    .footer_left {
      height: 197px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .text {
        display: flex;
        margin-top: 6px;
        align-items: center;
        font-size: 30px;
        .round {
          width: 29px;
          height: 29px;
          border-radius: 50%;
          background-color: #fff;
          margin: 0 13px 0 0;
        }
      }
      .iphone {
        margin-top: 13px;
        font-size: 16px;
        line-height: 2em;
      }
    }
    .code_img {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        margin-left: 85px;
      }
    }
  }
}
</style>
