<template>
  <div class="headers">
    <el-container>
      <!-- 顶部logo -->
      <el-header  class="el-header_tos">
        <div class="top">
          <!-- <img src="../assets/h_img/h_01.webp" class="desc-icon" /> -->
          <div class="header_name">高陵区公益性公墓</div>
          <router-link to="/">
            <!-- <img src="../assets/h_img/logo.png" class="icon" /> -->
          </router-link>
          <div class="mobile-box">
            <!-- <img src="../assets/h_img/h_02.webp" class="icon-phone" /> -->
            <div class="phone-right">
              <p class="tips_phone">咨询服务电话</p>
              <p class="phone">{{ info.phone }}</p>
            </div>
          </div>
        </div>
      </el-header>
      <div class="dhl_header">
        <!--  <el-menu
          background-color="#197381"
          active-text-color="#ffcd42"
          text-color="#fcfcfc"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1" id="el-menu-item"
            ><router-link to="/">首页</router-link></el-menu-item
          >
          <el-menu-item index="2" id="el-menu-item"
            ><router-link to="/jn">通知公告</router-link></el-menu-item
          >
          <el-menu-item index="3" id="el-menu-item"
            ><router-link to="/ct">政策法规</router-link></el-menu-item
          >
          <el-menu-item index="4" id="el-menu-item"
            ><router-link to="/my">殡葬文化</router-link></el-menu-item
          >
          <el-menu-item index="5" id="el-menu-item">介绍</el-menu-item>
          <el-menu-item index="6" id="el-menu-item">墓园购买</el-menu-item>
          <el-menu-item index="6" id="el-menu-item">殡仪公司</el-menu-item>
        </el-menu> -->
        <ul class="dh_ul">
          <li
            v-for="(item, index) in pageJson"
            :key="index"
            @click="navTo(item.path, index)"
            :class="{ select: index == selectVlue }"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </el-container>
  </div>
</template>

<script>
import { companyinfoSSelectOne } from "@/api/selectpage";
export default {
  data() {
    return {
      info:'',
      selectVlue: 0,
      input: "", //搜索框输入的值
      activeIndex: "1",
      activeIndex2: "1",
      pageJson: [
        {
          name: "首页",
          path: "/",
          val: 0,
        },
        {
          name: "墓园介绍",
          path: "/cemetery",
          val: 1,
        },
        {
          name: "通知公告",
          path: "/jn",
          val: 2,
        },
        {
          name: "政策法规",
          path: "/ct",
          val: 3,
        },
        {
          name: "墓园购买",
          path: "/CemeteryPurchase",
          val: 4,
        },
        {
          name: "殡葬文化",
          path: "/my",
          val: 5,
        },
        {
          name: "价格公示",
          path: "/PriceAnnouncement",
          val: 6,
        },
        {
          name: "网上祭奠",
          path: "/OnlineSacrifice",
          val: 7,
        },
        {
          name: "预约祭扫",
          path: "/SweepGuestyuyue",
          val: 8,
        },
        {
          name: "代客祭扫",
          path: "/SweepGuest",
          val: 9,
        },
        {
          name: "投诉与建议",
          path: "/complaintAdvice",
          val: 10,
        },
        {
          name:"联系我们",
          path:"/Phone",
          val:11,
        }


        /*  {
          name: "殡仪公司",
          path: "",
        }, */
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path) {
        let val = this.pageJson.filter((res) => res.path == to.path);
        if(val.length>=1){
           this.selectVlue=val[0].val
        }

      }

    },
  },
  created(){
    this.companyinfoSSelectOne()
  },
  methods: {
    companyinfoSSelectOne(){
      companyinfoSSelectOne().then(res=>{
        this.info=res.data
      })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    navTo(path, index) {
      // this.selectVlue = index;
      this.$router.push({
        path,
        query: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 顶部logo样式
/* .el-header {
  background: linear-gradient(to bottom, #dceeea, #fff);
} */
.el-header_tos{
  height: 100px !important;
}
.top {
  // padding: 0px 100px 0px 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .header_name {
    font-size:30px;
    color: #0ba09d;
  }
}
.desc-icon {
  position: absolute;
  width: 368px;
  height: 26px;
  bottom: 32px;
  left: 280px;
}

.icon-phone {
  width: 45px;
  height: 45px;
  margin-right: 8px;
  float: left;
}
//导航栏样式
.dhl_header {
  // background-color: #ffffff;
  // padding: 22px 20px;
  .dh_ul {
    background: #0ba09d;
    display: flex;
    align-items: center;
    padding: 0 25px;
    // justify-content: flex-start;
    justify-content: center;
    li {
      height: 54px;
      color: #ffffff;
      font-size: 20px;
      line-height: 54px;
      width: 187px;

      cursor: pointer;
      text-align: center;
    }
  }
}

.phone-right {
  .phone {
    font-size: 26px;
    color: #0ba09d;
    font-weight: 600;
  }
  .tips_phone {
    font-size: 26px;
    margin-bottom: 5px;
    color: #b59478;
    text-align: right;
  }
}
.select {
  background: #2bb8ce;
  color: #ffffff;
}
</style>
