import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/mywenzhang',
    name: 'mywenzhang',
    component: () =>
        import('../views/MyWenzhang.vue'),
},
{
    path: '/xiangce',
    name: 'xiangce',
    component: () =>
        import('../views/XiangCe.vue'),
},
{
    path: '/myindex',
    name: 'myindex',
    component: () =>
        import('../views/MyIndex.vue'),
},


{
    path: '/user',
    name: 'user',
    component: () =>
        import('../views/Index.vue'),
},


{
    path: '/guangchang/renqijinian',
    name: 'renqijinian',
    component: () =>
        import('../views/Guangchangrenqijinian.vue'),
},
    {
        path: '/SweepGuestyuyue',
        name: 'SweepGuestyuyue',
        component: () =>
            import('../views/SweepGuestyuyue.vue'),
    },

{
    path: '/jibai',
    name: 'jibai',
    component: () =>
        import('../views/Jibai.vue'),
    redirect: '/jibai/formone',
    children: [{
        path: 'formone',
        name: 'formone',
        component: () =>
            import('../views/Formone.vue'),
    },
    {
        path: 'formtwo',
        name: 'formtwo',
        component: () =>
            import('../views/Formtwo.vue'),
    },
    {
        path: 'formthree',
        name: 'formthree',
        component: () =>
            import('../views/Formthree.vue'),
    },
    {
        path: 'formfour',
        name: 'formfour',
        component: () =>
            import('../views/Formfour.vue'),
    },
    {
        path: 'formfive',
        name: 'formfive',
        component: () =>
            import('../views/Formfive.vue'),
    },
    {
        path: 'formsix',
        name: 'formsix',
        component: () =>
            import('../views/Formsix.vue'),
    },
    ]
},

{
    path: '/ld',
    name: 'listproduct',
    component: () =>
        import('../views/ListProduct.vue'),
},


{
    path: '/',
    name: 'indexfloor',
    component: () =>
        import('../views/IndexFloor.vue'), //首页
},
{
    path: '/OnlineSacrifice',
    name: 'OnlineSacrifice',
    component: () =>
        import('../views/OnlineSacrifice.vue'),//网上祭祀
},
{
    path: '/BurialService',
    name: 'BurialService',
    component: () =>
        import('../views/BurialService.vue'),//安葬服务
},
{
    path: '/jn',
    name: 'jinianguangchang',
    component: () =>
        import('../views/Jinianguangchang.vue'),//通告公告
},
{
    path: '/my',
    name: 'my',
    component: () =>
        import('../views/My.vue'), //殡葬文化
},
    {
        path: '/SweepGuest',
        name: 'my',
        component: () =>
            import('../views/SweepGuest.vue'),
    },
    {
        path: '/PriceAnnouncement',
        name: 'PriceAnnouncement',
        component: () =>
            import('../views/PriceAnnouncement.vue'),
    },
    {
        path: '/complaintAdvice',
        name: 'complaintAdvice',
        component: () =>
            import('../views/complaintAdvice.vue'),
    },
{
    path: '/ct',
    name: 'ctwh',
    component: () =>
        import('../views/CTwh.vue'), //政策法规
},
{
    path: '/CemeteryPurchase',
    name: 'CemeteryPurchase',
    component: () =>
        import('../views/CemeteryPurchase.vue'), //墓园购买
},
{
    path: '/cemetery',
    name: 'cemetery',
    component: () =>
        import('../views/cemetery.vue'), //墓园介绍
},
{
    path: '/purchase',
    name: 'purchase',
    component: () =>
        import('../views/purchase.vue'), //墓园购买
},
{
    path: '/detail',
    name: 'detail',
    component: () =>
        import('../views/detail.vue'), //文章详情
},{
    path:'/Phone',
    name:'phone',
    component:()=> import('../views/Phone.vue')
},
{
    path: '/guide',
    name: 'guide',
    component: () =>
        import('../views/guide.vue'), //指导
},


{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutView.vue')

},

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
