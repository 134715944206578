import request from "@/request/request";
// 根据类型查询文章 type 0=》公告  1=》资讯 2=》政策法规
export function selectbytype(params) {
  return request({
    url: "/article/selectbytype",
    method: "GET",
    params:params,
  });
}

// 安葬类型
export function selectpage(params) {
  return request({
    url: "/burialType/selectpage",
    method: "GET",
    params:params,
  });
}
// 类型详情
export function selectpageDetail(id) {
  return request({
    url: "/burialType/selectpage/"+id,
    method: "GET",
  });
}
// 安葬申请
export function addone(params) {
  return request({
    url: "/burialfor/addone",
    method: "POST",
    data:params,
  });
}
export function helpAddone(params) {
  return request({
    url: "/help/addone",
    method: "POST",
    data:params,
  });
}
export function appointmentAddone(params) {
  return request({
    url: "/appointment/addone",
    method: "POST",
    data:params,
  });
}
export function suggestionAdd(params) {
  return request({
    url: "/suggestion/addone",
    method: "POST",
    data:params,
  });
}
// 网上祭祀
export function sacrificecloudAddone(params) {
  return request({
    url: "/sacrificecloud/addone",
    method: "POST",
    data:params,
  });
}
//祭祀列表
export function sacrificecloudSelectpage(params) {
  return request({
    url: "/sacrificecloud/selectpage",
    method: "GET",
    params:params,
  });
}

//墓园列表

export function cemeteriesSelectpage(params) {
  return request({
    url: "/cemeteries/selectpage",
    method: "GET",
    params:params,
  });
}

// 墓园详情

export function cemeteriesSelectbyid(id) {
  return request({
    url: "/cemeteries/selectbyid/"+id,
    method: "GET",
  });
}
export function priceGet(id) {
  return request({
    url: "/price/get",
    method: "GET",
  });
}
// 文章详情
export function articleSelectbyid(id) {
  return request({
    url: "/article/selectbyid/"+id,
    method: "GET",
  });
}
// 获取图片
export function pictureSelectallbytype(params) {
  return request({
    url: "/picture/selectallbytype",
    method: "GET",
    params:params,
  });
}

export function companyinfoSSelectOne(query) {
  return request({
    url: '/companyinfo/selectOne',
    method: 'get',
    params: query
  })
}

// 查询接待人员
export function servicerSelectpage(query) {
  return request({
    url: '/servicer/selectpage',
    method: 'get',
    params: query
  })
}
export function menuAll(query) {
  return request({
    url: '/menu/all',
    method: 'get',
    params: query
  })
}


