// 1.导入axios
import axios from "axios";
// 2.创建axios副本
const _axios = axios.create({
  // baseURL: 'http://192.168.2.102:520/app',
  baseURL:'https://jisi.guangruankeji.com/wx/app',
  timeout: 30000
});
// 3.请求拦截器
_axios.interceptors.request.use(
  (config) => {
    // console.log(config);
    return config;
  },
  (error) => {
    // console.log(error, "请求失败之后的结果");
    return Promise.reject(error);
  }
);
// 4.响应拦截器
_axios.interceptors.response.use(
  (res) => {
    if (res.status == 200) {

      return res.data;

    } else {
      // 错误的提示信息
      return Promise.reject(res.data.message);
    }
  },
  (error) => {
    // return Promise.reject(error);
    return error, "响应失败的结果";
  }
);
// 5.暴露出去
export default _axios;
